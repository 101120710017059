import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Input, Button, Alert, message } from 'antd';
import signupImg from '../../images/signup-page.gif';
import * as Constants from '../../common/constants';
import React, { useState, useEffect } from 'react';
import './css/signup.css';

const Signup = () => {
    const [isSignupBtnLoading, setIsSignupBtnLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            window.location.href = '/dashboard';
        } else {
            localStorage.removeItem('userData');
        }
    });

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError("");
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError("");
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError("");
    };

    const handleFullNameChange = (e) => {
        setFullName(e.target.value);
        setError("");
    };

    const handleSignupClick = async () => {
        let errorMsg = "";
        if (!fullName) {
            errorMsg = "Please enter your Full Name.";
        } else if (!email) {
            errorMsg = "Please enter your email address.";
        } else if (!password) {
            errorMsg = "Please enter your password.";
        } else if (!confirmPassword) {
            errorMsg = "Please enter your confirm password.";
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                errorMsg = "Please enter a valid email address.";
            } else if (password.length <= 6) {
                errorMsg = "Password must be more than 6 characters long.";
            } else if (!/[A-Z]/.test(password)) {
                errorMsg = "Password must contain at least one uppercase letter.";
            } else if (!/\d/.test(password)) {
                errorMsg = "Password must contain at least one number.";
            } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
                errorMsg = "Password must contain at least one special character.";
            } else if (password !== confirmPassword) {
                errorMsg = "Password and confirm password do not match. Please try again.";
            }
        }

        if (errorMsg) {
            setError(errorMsg);
        } else {
            setIsSignupBtnLoading(true)
            fetch(Constants.APIS.USER_SIGNUP, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    email: email,
                    password: password,
                    fullName: fullName
                })
            })
                .then(response => response.json())
                .then(data => {
                    setIsSignupBtnLoading(false)
                    if (data.responseCode === Constants.CODE_SUCCESS) {
                        setEmail('');
                        setPassword('');
                        setConfirmPassword('');
                        setFullName('');
                        message.success(data.responseMessage)
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 2000);
                    } else {
                        setError(data.responseMessage);
                    }
                })
                .catch(err => {
                    setError("An error occurred during signup. Please try again.");
                });
        }
    };

    return (
        <div className='signup-wrapper'>
            <div className="signup-container">
                <div className="signup-leftSection">
                    <img src={signupImg} className='signupImg' alt="Signup" />
                </div>
                <div className="signup-rightSection">
                    {error && <Alert message={error} type="error" showIcon />}
                    <p className="signup-welcome">𝓒𝓻𝓮𝓪𝓽𝓮 𝓐𝓬𝓬𝓸𝓾𝓷𝓽</p>
                    <p className="signupText">Please signup to create a new account</p>
                    <Input
                        type='text'
                        className="signup-email"
                        value={fullName}
                        onChange={handleFullNameChange}
                        size="large"
                        placeholder="Full Name" />
                    <Input
                        type='email'
                        className="signup-email"
                        value={email}
                        onChange={handleEmailChange}
                        size="large"
                        placeholder="Email" />
                    <Input.Password
                        className="signup-password"
                        onChange={handlePasswordChange}
                        size="large"
                        value={password}
                        placeholder="Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Input.Password
                        className="signup-password"
                        onChange={handleConfirmPasswordChange}
                        size="large"
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <Button className='signupBtn' loading={isSignupBtnLoading} onClick={handleSignupClick}>
                        Signup
                    </Button>
                    <div className="login">
                        Already have an account?<a href="/"> Login</a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Signup;

import { UserOutlined, PlayCircleOutlined, LogoutOutlined,DollarOutlined,PhoneOutlined  } from '@ant-design/icons';
import { Layout, Menu, Avatar, Row, Col, Tag, Alert, Button } from 'antd';
import DemoProjectList from '../demoAI/demoProjectList';
import * as Constants from '../../common/constants';
import BuyCredit from '../payments/buyCredit';
import React, { useState } from 'react';
import utils from '../../common/utils'
import './css/dashboard.css';
const { Sider } = Layout;

const Dashboard = () => {
  const [selectedSideBarMenuKey, setSelectedSideBarMenuKey] = useState(1);
  const [isPaymentModalDisplay, setIsPaymentModalDisplay] = useState(false);
  const [userCredits, setUserCredits] = useState();
  const userData = utils.getUserSessionData();
  const user = { name: userData.email };

  const handleSidebarMenuClick = (e) => {
    if (e && e.key && selectedSideBarMenuKey != e.key) {
      setSelectedSideBarMenuKey(e.key)  // Update selected menu key
      if (Constants.SIDE_MENU_BAR_MAP.DEMO_AI == e.key) {
        setSelectedSideBarMenuKey(e.key)  // Specific handling for DEMO_AI menu item
      } else if (Constants.SIDE_MENU_BAR_MAP.LOGOUT == e.key) {
        utils.deleteWebSession()  // Handle logout action
      }
    }
  }

  return (
    <Layout className="layout">
      <Sider width={250} className="site-layout-background">
        <div className="user-info">
          <Avatar size={80} icon={<UserOutlined />} />
          <p className="user-name">{user.fullName || user.name}</p>
        </div>
        <Menu mode="inline" defaultSelectedKeys={['1']} onClick={handleSidebarMenuClick} className="menu">
          <Menu.Item key="1" icon={<PlayCircleOutlined />}>
            Video AI
          </Menu.Item>
          {/* <Menu.Item key="2" icon={<LogoutOutlined />}>
            Docs AI
          </Menu.Item> */}
          {/* <Menu.Item key="3" icon={<SettingOutlined />}>
            Settings
          </Menu.Item> */}
          <Menu.Item key="4" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Row className='header'>
          <Col span={8}> </Col>
          <Col span={8}> </Col>
          <Col span={8} style={{ textAlign: 'end' }}>
          {/* <Tag 
            icon={<DollarOutlined />} 
            color="#6c63ff"
            style={{cursor:'pointer'}} 
            onClick={() => setIsPaymentModalDisplay(true)}> 
            Buy Credits 
          </Tag> */}
          <Tag 
            icon={<PhoneOutlined />} 
            color="#6c63ff"
            style={{cursor:'pointer'}} 
            onClick={()=>window.open('https://calendly.com/rallrajveer', '_blank')}
            > 
            Content Us 
          </Tag>
            <Tag color="geekblue">Available Credits: {userCredits || 0}</Tag>
          </Col>
        </Row>
        {Constants.SIDE_MENU_BAR_MAP.DEMO_AI == selectedSideBarMenuKey &&
          <DemoProjectList
            setUserCredit={(credit) => setUserCredits(credit)}
          />
        }
      </Layout>
      {isPaymentModalDisplay &&
        <BuyCredit
          isPaymentModalDisplay={isPaymentModalDisplay}
          closePaymentModal={() => setIsPaymentModalDisplay(false)}
          userData={userData}
        />
      }
    </Layout>
  );
};

export default Dashboard;

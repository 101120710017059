import { UploadOutlined, SmileTwoTone, CheckCircleOutlined, ExclamationCircleOutlined,PhoneOutlined, ClockCircleOutlined, UserOutlined, LeftOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Upload, Alert, message, Button, Row, Col, Tag, Avatar, Table } from 'antd';
import * as Constants from '../../common/constants';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import utils from '../../common/utils'
import processTrackerImg from '../../images/processTracker.gif'
import { Select } from 'antd';
import './css/aiVideo.css';
import Alloy from '../../aiVoices/Alloy.mp3'
import Nova from '../../aiVoices/Nova.mp3'
import Onyx from '../../aiVoices/Onyx.mp3'
import Shimmer from '../../aiVoices/Shimmer.mp3'

const { Option } = Select;

export default function AiVideoGenerator(props) {

    const [isUploadVideoClick, setIsUploadVideoClick] = useState(false);
    const [projectAllData, setProjectAllData] = useState();
    const [uploadFile, setUploadFile] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [processStepList, setProcessStepList] = useState([])
    const [currentStep, setCurrentStep] = useState(1);
    const [isVideoInProcess, setIsVideoInProcess] = useState(false);
    const [inProcessMsg, setInProcessMsg] = useState();
    const [aiVoiceData, setAiVoiceData] = useState();

    const timeoutId = setTimeout(() => controller.abort(), 1200000);
    const userData = utils.getUserSessionData();
    const controller = new AbortController();
    let params = useParams();
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    const aiVoiceList = [
        {
            'displayName': 'Alloy',
            'filePath': Alloy,
            'type': 'openAI'
        },
        {
            'displayName': 'Onyx',
            'filePath': Onyx,
            'type': 'openAI'
        },
        {
            'displayName': 'Nova',
            'filePath': Nova,
            'type': 'openAI'
        },
        {
            'displayName': 'Shimmer',
            'filePath': Shimmer,
            'type': 'openAI'
        },
    ]


    useEffect(() => {
        getDemoVideoStatus();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isVideoInProcess) {
                getDemoVideoStatus();
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, [isVideoInProcess]);


    const getDemoVideoStatus = () => {
        setIsPageLoading(true);
        fetch(Constants.APIS.GET_UPLOADED_VIDEO_STATUS, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                userDemoVideoProjectMapId: parseInt(params.id)
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    setProjectAllData(data.data);
                    if (data.data.stage == 'merge') {
                        setProcessStepList([
                            { id: 1, title: "Upload Video", symbol: <UploadOutlined /> },
                            { id: 2, title: "Video Queued", symbol: <ClockCircleOutlined /> },
                            { id: 3, title: "Processing Video", symbol: <LoadingOutlined /> },
                            { id: 4, title: "Completed", symbol: <CheckCircleOutlined /> },
                        ]);
                        if (data.data.mergeStatus == 'inQueue') {
                            setIsVideoInProcess(true)
                            setCurrentStep(2)
                            setInProcessMsg('The video is currently being processed. Please hold on for a moment or return to this page shortly.')

                        } else if (data.data.mergeStatus == 'inProcess') {
                            setCurrentStep(3)
                            setIsVideoInProcess(true)
                            setInProcessMsg('The video is currently being processed. Please hold on for a moment or return to this page shortly.')
                        } else {
                            setIsUploadVideoClick(false)
                            setAiVoiceData();
                            setUploadFile(null);
                            setIsVideoInProcess(false)
                        }
                    }
                }
                setIsPageLoading(false);
            })
    }

    const handleUploadChange = (info) => {
        let uploadedFile = { ...info.file };
        uploadedFile = uploadedFile.originFileObj;
        setUploadFile(uploadedFile);
    };

    const beforeUpload = async (file) => {
        const isMp4 = file.type === 'video/mp4';
        if (!isMp4) {
            message.error('You can only upload MP4 files!');
            return Upload.LIST_IGNORE;
        }

        // Check video duration
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        return new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
                if (video.duration > 300) {
                    message.error('Video duration cannot exceed 5 minutes.');
                    URL.revokeObjectURL(video.src);
                    resolve(Upload.LIST_IGNORE);
                } else {
                    URL.revokeObjectURL(video.src); // Clean up
                    resolve(true);
                }
            };
            video.onerror = () => {
                message.error('Failed to load video.');
                URL.revokeObjectURL(video.src); // Clean up
                reject();
            };
        });
    };

    const downloadVideo = async () => {
        let url1 = Constants.BASE_URL_Glob + projectAllData?.mergeFilePath;
        try {
            const response = await fetch(url1, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${projectAllData?.mergeFilePath.split("/")[1]}`; // Name the file to be downloaded
            document.body.appendChild(link);
            link.click();

            // Clean up
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        }
    }

    const handleAiVoiceChange = (item) => {
        let tmpData = aiVoiceList.find(e => e.displayName == item)
        if (tmpData) {
            setAiVoiceData(tmpData)
        }
    }

    const uploadDemoVideo = () => {
        if (!uploadFile) {
            message.error('Video file not uploaded. Please upload a video file to continue.')
        } else if(!aiVoiceData){
            message.error('Please select AI voice to continue.')
        } else {
            setIsUploadVideoClick(true);
            const hide = message.loading("Your file is currently being uploaded. Please wait", 0);

            const formData = new FormData();
            formData.append('email', userData.email);
            formData.append('accessToken', userData.accessToken);
            formData.append('userDemoVideoProjectMapId', params.id);
            formData.append('voiceType', aiVoiceData?.type);
            formData.append('voiceName', aiVoiceData?.displayName);
            if (uploadFile) {
                formData.append('videoFile', uploadFile);
            }

            fetch(Constants.APIS.UPLOAD_DEMO_VIDEO, {
                method: "POST",
                mode: 'cors',
                body: formData,
                signal: controller.signal
            }).then(response => {
                clearTimeout(timeoutId);
                return response.json()
            })
                .then(data => {
                    hide()
                    if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                        utils.deleteWebSession();
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        message.success(data.responseMessage)
                        getDemoVideoStatus()
                    } else {
                        setIsUploadVideoClick(false)
                        message.error(data.responseMessage)
                    }
                })
        }
    };

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="text" icon={<DeleteOutlined />} onClick={() => setUploadFile(null)}>
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Row className="demoWs-top-bar">
                <Col span={8}>
                    <div className="demoWs-top-bar-left">
                        <LeftOutlined style={{ fontSize: '20px', color: 'white' }} onClick={() => window.history.back()} />
                    </div>
                </Col>
                <Col span={8}>
                    <div className="demoWs-top-bar-middle">
                        {projectAllData?.projectName}
                    </div>
                </Col>
                
                <Col span={8} className='demoWs-top-bar-right'>
                <Tag 
            icon={<PhoneOutlined />} 
            color="#6c63ff"
            style={{cursor:'pointer'}} 
            onClick={()=>window.open('https://calendly.com/rallrajveer', '_blank')}
            > 
            Content Us 
          </Tag>
                    <Tag color="geekblue">Available Credits: {projectAllData?.totalCredit || 0}</Tag> |&nbsp;
                    <span className='demo-ws-top-bar-username'>{userData.fullName || userData.email}</span>
                    <Avatar size="small" icon={<UserOutlined />} />
                </Col>
            </Row>


            {(projectAllData?.stage == null || projectAllData?.mergeStatus == 'failed') && parseInt(projectAllData?.totalCredit) > 0 &&
                <div className="newVideo-main-content">
                    <div className="newVideo-upload-container">
                        <Upload.Dragger
                            name="files"
                            onChange={handleUploadChange}
                            showUploadList={false}
                            multiple={false}
                            accept=".mp4"
                            beforeUpload={beforeUpload}
                            className="demoWs-upload-box">
                            <p className="newVideo-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="uploadVideo-ant-upload-text">Drag and drop demo video file</p>
                        </Upload.Dragger>
                        {uploadFile &&
                            <div>
                                <h5 className="newVideo-description-heading">Uploaded File: </h5>
                                <Table
                                    dataSource={[uploadFile]}
                                    columns={columns}
                                    pagination={false}
                                    size="small"
                                    showHeader={false}
                                />
                            </div>
                        }
                        {parseInt(projectAllData?.totalCredit) > 0 &&
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Select
                                    style={{ width: 130 }}
                                    placeholder="AI Voice"
                                    onChange={handleAiVoiceChange}
                                >
                                    {
                                        aiVoiceList.map(item => {
                                            return <Option value={item.displayName}>{item.displayName}</Option>
                                        })
                                    }

                                </Select>
                                <Button
                                    className={"newVideo-uploadVideo-btn"}
                                    loading={isUploadVideoClick}
                                    onClick={uploadDemoVideo}>
                                    Upload Video
                                </Button>


                            </div>
                        }
                        {parseInt(projectAllData?.totalCredit) > 0 && aiVoiceData &&
                            <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
                                <audio
                                    ref={audioRef}
                                    src={aiVoiceData?.filePath}
                                    controls
                                />

                            </div>
                        }
                    </div>
                </div>
            }

            {projectAllData?.mergeStatus == 'failed' && projectAllData?.stage == 'merge' &&
                <div className='tracker-error-messgae'>
                    <Alert
                        style={{ fontSize: '16px' }}
                        description={"We couldn’t process your video. Please try again. If the issue continues, contact us for help."}
                        type="error"
                        showIcon
                        icon={<ExclamationCircleOutlined />}
                    />
                </div>
            }

            {projectAllData?.stage == "merge" && !['complete', 'failed'].includes(projectAllData?.mergeStatus) &&
                <>
                    <div className="tracker-main-content">
                        <div className="stepper-wrapper">
                            {processStepList?.map((step, index) => (
                                <div key={step.id} className="step-item">
                                    <div className="stepper-container">
                                        <div
                                            className={`step-circle ${currentStep >= step.id ? "active" : ""}`}
                                        >
                                            {currentStep > step.id ? <CheckCircleOutlined /> : currentStep === step.id ? <LoadingOutlined /> : step.icon}
                                        </div>
                                        <div
                                            className={`step-label ${currentStep >= step.id ? "active" : ""}`}
                                        >
                                            {step.title}
                                        </div>
                                        {index < processStepList.length - 1 && (
                                            <div
                                                className={`step-line ${currentStep > step.id ? "active" : ""}`}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="image-main-content">
                        <img src={processTrackerImg} height='280px' width='380px' />
                    </div>
                    <div className='tracker-error-messgae'>
                        <Alert
                            style={{ fontSize: '16px' }}
                            description={inProcessMsg}
                            type="info"
                            showIcon
                            icon={<SmileTwoTone />}
                        />
                    </div>

                </>
            }
            {projectAllData?.stage == "merge" && projectAllData?.mergeStatus == "complete" &&
                <>
                    <div className="tracker-main-content">
                        <div className="video-wrapper">
                            <video
                                ref={videoRef}
                                src={Constants.BASE_URL_Glob + projectAllData?.mergeFilePath}
                                controls
                                className="video-element"
                            />
                        </div>
                    </div>
                    {parseInt(projectAllData?.totalCredit) > 0 &&
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {/* <Button
                                className={"newVideo-uploadVideo-btn"}
                                onClick={uploadDemoVideo}>
                                Upload New Video
                            </Button> */}
                            <Button
                                className={"newVideo-uploadVideo-btn"}
                                // className={"download-Video-btn"}
                                onClick={downloadVideo}>
                                Download Video
                            </Button>
                        </div>
                    }
                </>
            }
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { Button, Row, Col, message, Modal, Input, Divider, Typography } from 'antd';
import * as Constants from '../../common/constants';
import utils from '../../common/utils';
import './css/buyCredit.css';
import { load } from "@cashfreepayments/cashfree-js";
const { Text, Title } = Typography;

export default function BuyCredit(props) {
    const userData = props?.userData;
    const [phoneNo, setPhoneNo] = useState('');
    const [credit, setCredit] = useState(10);
    const [isPaymentLaoder, setIsPaymentLoader] = useState(false);
    const [perCreditPrice, setPerCreditPrice] = useState(0);
    const [orderCurrency, setOrderCurrency] = useState('INR');
    
    let cashfree;
    var initializeSDK = async function () {          
        cashfree = await load({
            mode: process.env.CASHFREE_ENV == "test" ? "sandbox":"production"
        });
    }
    initializeSDK();

    useEffect(() => {
        getPaymentMasterData();
    }, []);

    const getPaymentMasterData = () => {
        fetch(Constants.APIS.GET_PAYMENT_MASTER_DATA, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    setPerCreditPrice(data.data.perCreditRate)
                    setOrderCurrency(data.data.orderCurrency)
                    setPhoneNo(data.data.phoneNo);

                    // message.success(data.responseMessage);
                } else {
                    message.error(data.responseMessage);
                }
            });
    };

    const handleMobileNoChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, '');
        setPhoneNo(numericValue);
    };

    const handleCreditChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, '');
        if (numericValue.length <= 4) {
            setCredit(Number(numericValue));

        }
    };

    const redirectPaymentPage = async(sessionId)=>{
        let checkoutOptions = {
            paymentSessionId: sessionId,
            redirectTarget: "_self",
        };
        cashfree.checkout(checkoutOptions);
    }

    const handleBuyCreditClick = () => {
        if (credit < 10) {
            message.error("Please purchase at least 10 credits.")
        } else {
            setIsPaymentLoader(true)
            fetch(Constants.APIS.INITIATE_NEW_PAYMENT, {
                method: "POST",
                mode: 'cors',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    email: userData.email,
                    accessToken: userData.accessToken,
                    phoneNo: phoneNo,
                    purchasedCredit: credit
                })
            }).then(response => response.json())
                .then(data => {
                    if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                        utils.deleteWebSession();
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        redirectPaymentPage(data.sessionId);
                    } else {
                        setIsPaymentLoader(false)
                        message.error(data.responseMessage);
                    }
                });
        }

    };

    // Inside BuyCredit component
    return (
        <Modal
            title={<Title level={4}>Buy Credits</Title>} // Use Title component for the header
            visible={props?.isPaymentModalDisplay}
            onCancel={props?.closePaymentModal}
            footer={[
                <Button key="submit" className='buy-btn' loading={isPaymentLaoder} onClick={handleBuyCreditClick}>
                    BUY NOW
                </Button>,
            ]}
            className='modal-container'
            centered
            width={400}
        >
            <Divider orientation="left" style={{ textAlign: 'center', marginTop: 20 }} />
            <div className='form-item'>
                <label className='form-label'>
                    <Text strong={true}>Phone Number</Text><span className='required-star'>*</span>
                </label>
                <Input
                    value={phoneNo}
                    onChange={handleMobileNoChange}
                    required
                    placeholder="Enter your phone number"
                    maxLength={10}
                    className='input-field'
                />
            </div>

            <div className='form-item'>
                <label className='form-label'>
                    <Text strong={true}>Credits</Text>
                </label>
                <Input
                    onChange={handleCreditChange}
                    type='number'
                    value={credit}
                    required
                    min={10}
                    placeholder="Enter credits"
                    className='input-field'
                />
            </div>

            <div className='charge-info'>
                Per Credit: <span className='charge-amount'>{perCreditPrice} {orderCurrency}</span>
            </div>

            <Divider className='modal-divider' />

            <div className='total-amount'>
                Total Amount: <span className='amount'>{credit * parseInt(perCreditPrice)} {orderCurrency}</span>
            </div>
        </Modal>
    );
}

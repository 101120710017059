module.exports = {
    getUserSessionData: function() {
        const userData = localStorage.getItem('userData');
        if(userData){
            return JSON.parse(userData);
        }
        return {}
    },

    deleteWebSession:function(){
        localStorage.clear();
        window.location.href='/'
    }
}
import { UploadOutlined, DeleteOutlined, } from '@ant-design/icons';
import { Upload, Input, message, Button, Table } from 'antd';
import * as Constants from '../../common/constants';
import React, { useState} from 'react';
import utils from '../../common/utils'
import './css/uploadNewVideo.css';

const { TextArea } = Input;

export default function UploadNewVideo(props) {

    const [isUploadVideoClick, setIsUploadVideoClick] = useState(false);
    const [videoDescription, setVideoDescription] = useState('');
    const [uploadFile, setUploadFile] = useState(null);

    const timeoutId = setTimeout(() => controller.abort(), 180000);
    const userData = utils.getUserSessionData();
    const controller = new AbortController();
    let params = props.params;


    const handleUploadChange = (info) => {
        let uploadedFile = { ...info.file };
        uploadedFile = uploadedFile.originFileObj;
        setUploadFile(uploadedFile);
    };

    const beforeUpload = async (file) => {
        const isMp4 = file.type === 'video/mp4';
        if (!isMp4) {
            message.error('You can only upload MP4 files!');
            return Upload.LIST_IGNORE;
        }

        // Check video duration
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        return new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
                if (video.duration > 240) { 
                    message.error('Video duration cannot exceed 4 minutes.');
                    URL.revokeObjectURL(video.src); 
                    resolve(Upload.LIST_IGNORE);
                } else {
                    URL.revokeObjectURL(video.src); // Clean up
                    resolve(true);
                }
            };
            video.onerror = () => {
                message.error('Failed to load video.');
                URL.revokeObjectURL(video.src); // Clean up
                reject();
            };
        });
    };

    const uploadDemoVideo = () => {
        if (!uploadFile) {
            message.error('Video file not uploaded. Please upload a video file to continue.')
        } else if (!videoDescription) {
            message.error("Video prompt is required. Please provide a video prompt to proceed.");
        } else {
            setIsUploadVideoClick(true);
            const hide = message.loading("Your file is currently being uploaded. Please wait", 0);

            const formData = new FormData();
            formData.append('email', userData.email);
            formData.append('accessToken', userData.accessToken);
            formData.append('userDemoVideoProjectMapId', params.id);
            formData.append('videoDescription', videoDescription);
            if (uploadFile) {
                formData.append('videoFile', uploadFile);
            }

            fetch(Constants.APIS.UPLOAD_DEMO_VIDEO, {
                method: "POST",
                mode: 'cors',
                body: formData,
                signal: controller.signal
            }).then(response => {
                clearTimeout(timeoutId);
                return response.json()
            })
                .then(data => {
                    hide()
                    if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                        utils.deleteWebSession();
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        message.success(data.responseMessage)
                        props.getDemoVideoStatus()
                    } else {
                        setIsUploadVideoClick(false)
                        message.error(data.responseMessage)
                    }
                })
        }
    };

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="text" icon={<DeleteOutlined />} onClick={() => setUploadFile(null)}>
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div className="newVideo-main-content">
            <div className="newVideo-upload-container">
                <Upload.Dragger
                    name="files"
                    onChange={handleUploadChange}
                    showUploadList={false}
                    multiple={false}
                    accept=".mp4"
                    beforeUpload={beforeUpload}
                    className="demoWs-upload-box">
                    <p className="newVideo-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                    <p className="uploadVideo-ant-upload-text">Drag and drop demo video file</p>
                </Upload.Dragger>
                {uploadFile &&
                    <div>
                        <h5 className="newVideo-description-heading">Uploaded File: </h5>
                        <Table
                            dataSource={[uploadFile]}
                            columns={columns}
                            pagination={false}
                            size="small"
                            showHeader={false}
                        />
                    </div>
                }
                <>
                    <h5 className="newVideo-description-heading">Video Description</h5>
                    <TextArea
                        className="newVideo-textarea"
                        value={videoDescription}
                        onChange={(event) => setVideoDescription(event.target.value)}
                        rows={10}
                        placeholder="Enter video description"
                    />
                </>
                <Button
                    className={"newVideo-uploadVideo-btn"}
                    loading={isUploadVideoClick}
                    onClick={uploadDemoVideo}>
                    Upload Video
                </Button>
            </div>
        </div>
    )
}

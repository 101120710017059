import { PlusOutlined, DeleteOutlined,CheckCircleOutlined,SyncOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { Layout, Table, Button, Space, message, Modal, Input, Alert,Tag } from 'antd';
import * as Constants from '../../common/constants';
import React, { useState, useEffect } from 'react';
import utils from '../../common/utils'

const { Content } = Layout;

const DemoProjectList = (props) => {
    const [iscreateProjectLoading, setIscreateProjectLoading] = useState(false);
    const [isLoadingProjectData, setIsLoadingProjectData] = useState(false);
    const [displayNameModal, setDisplayNameModal] = useState(false);
    const [allProjectList, setAllProjectList] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [userCredits, setUserCredits] = useState(0);
    const userData = utils.getUserSessionData();

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        // {
        //     title: 'Current Stage',
        //     dataIndex: 'currentStage',
        //     key: 'currentStage',
        // },
        {
            title: 'Stage Process',
            dataIndex: 'stageProcess',
            key: 'stageProcess',
            render: (text, record) => displayProcessColumnData(record.stageProcess),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <DeleteOutlined onClick={() => deleteProject(record.key)} />
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getAllProjectList();
    }, []);

    const displayProcessColumnData = (status)=>{
        if(status){
            if(status == 'complete'){
                return  <Tag icon={<CheckCircleOutlined />} color="success"> Completed </Tag>
            }else if(['inQueue','inProcess','error'].includes(status)){
                return <Tag icon={<SyncOutlined spin />} color="processing"> Processing </Tag>
            }else if(status=='failed'){
                return  <Tag icon={<CloseCircleOutlined />} color="error"> Failed </Tag>
            }
        }else{
            return '-'
        }

    }; 

    const handleRowClick = (record) => {
        window.location.href = `video/${record.key}`
    };

    const handleCancelNameModal = () => {
        setProjectName("")
        setDisplayNameModal(false);
    }

    const deleteProject = (id) => {
        fetch(Constants.APIS.DELETE_USER_PROJECT, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
                userDemoVideoProjectMapId: id
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    message.success(data.responseMessage);
                    getAllProjectList();
                } else {
                    message.error(data.responseMessage);
                }
            })
    }

    const handleCreateProjectSave = () => {
        if (!projectName) {
            message.error('Project name is required. Please provide a name for your project.')
        } else {
            setIscreateProjectLoading(true)
            fetch(Constants.APIS.CREATE_NEW_PROJECT, {
                method: "POST",
                mode: 'cors',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                    email: userData.email,
                    accessToken: userData.accessToken,
                    projectName: projectName
                })
            }).then(response => response.json())
                .then(data => {
                    if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                        utils.deleteWebSession();
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        handleCancelNameModal();
                        getAllProjectList();
                        message.success(data.responseMessage);
                    } else {
                        message.error(data.responseMessage)
                    }
                    setIscreateProjectLoading(false)
                })
        }
    }

    const getAllProjectList = () => {
        setIsLoadingProjectData(true)
        fetch(Constants.APIS.GET_ALL_USER_PROJECTS, {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                email: userData.email,
                accessToken: userData.accessToken,
            })
        }).then(response => response.json())
            .then(data => {
                if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
                    utils.deleteWebSession();
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    let finalDisplayList = [];
                    for (let i = 0; i < data.data.length; i++) {
                        let tmpData = data.data[i];
                        finalDisplayList.push({
                            key: tmpData.userDemoVideoProjectMapId,
                            no: i + 1,
                            projectName: tmpData.projectName,
                            currentStage:tmpData.stage? tmpData.stage?.substring(0, 1).toUpperCase() + tmpData.stage?.substring(1) : '-',
                            stageProcess:tmpData.status
                        })
                    }
                    setAllProjectList(finalDisplayList)
                    setUserCredits(data.totalCredit)
                    props.setUserCredit(data.totalCredit)
                } else {
                    message.error(data.responseMessage);
                }
                setIsLoadingProjectData(false)
            })
    }

    const displayNoCreditMsg = () => {
        if (isLoadingProjectData) {
            return false
        } else if (!userCredits || parseFloat(userCredits) < 1) {
            return true
        } else {
            return false
        }
    }

    const displayCreateNewProjectBtn = () => {
        if (!userCredits || parseFloat(userCredits) < 1) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            {displayNoCreditMsg() &&
                <Alert type="warning"
                    message="Insufficient credit balance detected. Please purchase additional credits to continue using our services."
                    className='cretedi-alert-msg'
                    showIcon
                    closable={false}
                />
            }
            <Content className="content">
                {displayCreateNewProjectBtn() &&
                    <div className='addProjectBtn'>
                        <Button className='create-new-project-btn' icon={<PlusOutlined />} onClick={()=>setDisplayNameModal(true)}>
                            Create New Project
                        </Button>
                    </div>
                }
                <Table
                    columns={columns}
                    loading={isLoadingProjectData}
                    dataSource={allProjectList}
                    pagination={{ pageSize: 10 }}
                    onRow={(record, rowIndex, colIdx) => ({
                        onClick: (event) => {
                            const actionMenuColumnIndex = columns.findIndex(col => col.dataIndex === 'action'); // Adjust 'actionMenu' to match your column dataIndex
                            if (event.target.closest(`.ant-table-cell:nth-child(${actionMenuColumnIndex + 1})`)) {
                                return;
                            }
                            handleRowClick(record);
                        },
                    })}
                    rowClassName="table-row"
                    rowKey="key"
                    size="small"
                />
            </Content>

            <Modal
                title="Enter Project Name"
                visible={displayNameModal}
                onCancel={handleCancelNameModal}
                footer={[
                    <Button key="back" className='create-new-project-btn' onClick={handleCancelNameModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" className='create-btn' loading={iscreateProjectLoading} onClick={handleCreateProjectSave}>
                        Create
                    </Button>,
                ]}
            >
                <Input type='text' value={projectName} onChange={(e)=>setProjectName(e.target.value)} />

            </Modal>
        </>
    )
}
export default DemoProjectList;

export const BASE_URL_Glob = process.env.REACT_APP_BASE_URL_API
const BASE_URL = process.env.REACT_APP_BASE_URL_API
export const APIS = {
    GET_UPLOADED_VIDEO_STATUS: BASE_URL+"getUploadedVideoStatus",
    SAVE_UPDATED_VIDEO_SCRIPT: BASE_URL+"saveUpdatedVideoScript",
    GET_ALL_USER_PROJECTS: BASE_URL+"getAllUserProjects",
    REGENRATE_NEW_SCRIPT: BASE_URL+"regenrateNewScript",
    DELETE_USER_PROJECT: BASE_URL+"deleteUserProject",
    GENERATE_DEMO_AUDIO: BASE_URL+"generateDemoAudio",
    CREATE_NEW_PROJECT: BASE_URL+"createNewProject",
    REUPLOAD_NEW_VIDEO: BASE_URL+"reUploadNewVideo",
    UPLOAD_DEMO_VIDEO: BASE_URL+"uploadDemoVideo",
    GET_PAYMENT_MASTER_DATA:BASE_URL+"getPaymentMasterData",
    INITIATE_NEW_PAYMENT:BASE_URL+"initiateNewPayment",
    PAYMENT_STATUS_NOTIFY:BASE_URL+"paymentStatusNotify",
    USER_SIGNUP: BASE_URL+"signup",
    USER_LOGIN: BASE_URL+"login",
}

export const SIDE_MENU_BAR_MAP = {
    DEMO_AI:1,
    Docs_AI:2,
    SETTINGS:3,
    LOGOUT:4,
}
export const NO_ACCESS_TO_USER = [10,11,1]
export const CODE_SUCCESS = 0;
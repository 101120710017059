import { CloudDownloadOutlined, InteractionOutlined, SaveOutlined, SoundOutlined, PlaySquareOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip, message, Modal, Input, Divider } from 'antd';
import * as Constants from '../../common/constants';
import React, { useEffect, useState } from 'react'
import utils from '../../common/utils'
import TurndownService from 'turndown';
import ReactQuill from 'react-quill';
import './css/leftSideContainer.css'
import MarkdownIt from 'markdown-it';

// import { load } from '@cashfreepayments/cashfree-js';
const { TextArea } = Input;


export default function LeftSideContainer(props) {
  const [videoDescription, setVideoDescription] = useState(props?.videoDescription)
  const [regenerateScriptModal, setregenerateScriptModal] = useState(false);
  const [uploadNewVideoModal, setUploadNewVideoModal] = useState(false);
  const [generatedScript, setGeneratedScript] = useState();

  useEffect(() => {
    if (props?.generatedScript) {
      const md = new MarkdownIt();
      const renderedHtml = md.render(props?.generatedScript);
      setGeneratedScript(renderedHtml);
    }
  }, []);

  const params = props?.params
  const userData = props?.userData

  const saveupdatedScript = () => {
    if (!generatedScript.replace(/<[^>]*>/g, '')) {
      message.error('Video script cannot be empty. Please provide a script to proceed.')
    } else {
      const turndownService = new TurndownService();
      const markdownText = turndownService.turndown(generatedScript);
      fetch(Constants.APIS.SAVE_UPDATED_VIDEO_SCRIPT, {
        method: "POST",
        mode: 'cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          email: userData.email,
          accessToken: userData.accessToken,
          userDemoVideoProjectMapId: parseInt(params.id),
          generatedScript: markdownText
        })
      }).then(response => response.json())
        .then(data => {
          if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
            utils.deleteWebSession();
          } else if (data.responseCode === Constants.CODE_SUCCESS) {
            message.success(data.responseMessage)
          } else {
            message.error(data.responseMessage)
          }
        })
    }
  }

  const generateDemoAudio = () => {
    fetch(Constants.APIS.GENERATE_DEMO_AUDIO, {
      method: "POST",
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        email: userData.email,
        accessToken: userData.accessToken,
        userDemoVideoProjectMapId: parseInt(params.id),
      })
    }).then(response => response.json())
      .then(data => {
        if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
          utils.deleteWebSession();
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          props?.getDemoVideoStatus()
        } else {
          message.error(data.responseMessage)
        }
      })
  }

  const handleRegenrateNewScript = () => {
    fetch(Constants.APIS.REGENRATE_NEW_SCRIPT, {
      method: "POST",
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        email: userData.email,
        accessToken: userData.accessToken,
        userDemoVideoProjectMapId: parseInt(params.id),
        videoDescription: videoDescription,
      })
    }).then(response => response.json())
      .then(data => {
        if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
          utils.deleteWebSession();
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          props?.getDemoVideoStatus()
        } else {
          message.error(data.responseMessage)
        }
      })
  }

  const handleUploadNewVideo = () => {
    fetch(Constants.APIS.REUPLOAD_NEW_VIDEO, {
      method: "POST",
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        email: userData.email,
        accessToken: userData.accessToken,
        userDemoVideoProjectMapId: parseInt(params.id),
      })
    }).then(response => response.json())
      .then(data => {
        if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
          utils.deleteWebSession();
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          props?.getDemoVideoStatus();
        } else {
          message.error(data.responseMessage)
        }
      })
  }

  // const handleCassFreePayment = async () => {
  //   const cashfree = await load({
  //     mode: "sandbox" //or production
  //   });

  //   let sessionId = '';

  //   fetch(Constants.APIS.SAVE_UPDATED_VIDEO_SCRIPT, {
  //     method: "POST",
  //     mode: 'cors',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body: new URLSearchParams({
  //       email: userData.email,
  //       accessToken: userData.accessToken,
  //     })
  //   }).then(response => response.json())
  //     .then(data => {
  //       if (Constants.NO_ACCESS_TO_USER.includes(data.responseCode)) {
  //         utils.deleteWebSession();
  //       } else if (data.responseCode === Constants.CODE_SUCCESS) {

  //         let checkoutOptions = {
  //           paymentSessionId: data.data.sessionId,
  //           returnUrl: "http://localhost:3000/",

  //         }
  //         cashfree.checkout(checkoutOptions).then(function (result) {
  //           if (result.error) {
  //             alert(result.error.message)
  //           }
  //           if (result.redirect) {
  //             console.log("Redirection")
  //           }
  //         });
  //       } else {
  //         message.error(data.responseMessage)
  //       }
  //     })
  // }

  const handleDownloadAudio = async () => {
    let url1 = Constants.BASE_URL_Glob + props?.audioFilePath;
    try {
      const response = await fetch(url1, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${props?.audioFilePath.split("/")[1]}`; // Name the file to be downloaded
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('There was an error downloading the file:', error);
    }
  }

  return (
    <div>
      <Row justify='end' style={{ marginTop: '10px' }}>
        <Tooltip title="Save Script">
          <Button
            shape="circle"
            icon={<SaveOutlined />}
            onClick={saveupdatedScript}
            className="leftSide-icon-button"
          />
        </Tooltip>
        <Tooltip title="Generate New Script">
          <Button
            shape="circle"
            icon={<InteractionOutlined />}
            onClick={() => { setregenerateScriptModal(true) }}
            className="leftSide-icon-button"
          />
        </Tooltip>
        <Tooltip title="Upload New Video">
          <Button
            shape="circle"
            icon={<PlaySquareOutlined />}
            onClick={() => setUploadNewVideoModal(true)}
            className="leftSide-icon-button"
          />
        </Tooltip>
        <Tooltip title={props?.audioFilePath ? 'Regenerate Audio' : "Generate Audio"}>
          <Button
            shape="circle"
            icon={<SoundOutlined />}
            onClick={generateDemoAudio}
            className="leftSide-icon-button"
          />
        </Tooltip>
        {props?.stage == 'audio' && props?.audioStatus == 'complete' &&
          <Tooltip title="Download Audio">
            <Button
              shape="circle"
              icon={<CloudDownloadOutlined />}
              onClick={handleDownloadAudio}
              className="leftSide-icon-button"
            />
          </Tooltip>
        }
      </Row>
      <div className="editor-container">
        <ReactQuill
          value={generatedScript}
          onChange={(value) => setGeneratedScript(value)}
          className="leftSide-custom-quill"
          theme="snow"
        />
      </div>

      {/*  This Modal is for Generate a New Script */}
      <Modal
        title="Regenerate Script"
        visible={regenerateScriptModal}
        onCancel={() => { setregenerateScriptModal(false); setVideoDescription(props?.videoDescription) }}
        footer={[
          <Button key="back" className='create-new-project-btn'
            onClick={() => { setregenerateScriptModal(false); setVideoDescription(props?.videoDescription) }}
          >
            Cancel
          </Button>,
          <Button key="submit" className='create-btn'
            onClick={handleRegenrateNewScript}
          >
            Regenerate
          </Button>,
        ]}
      >
        <Divider className='new-Script-divider' />
        <h5>Uploaded File:
          <span style={{ fontWeight: "normal", marginLeft: '5px' }}>
            {props?.videoFilePath.split("/")[1].split("_-_")[1]}
          </span>
        </h5>
        <h5 className="newVideo-description-heading">Video Description:</h5>
        <TextArea
          className="newVideo-textarea"
          value={videoDescription}
          onChange={(event) => setVideoDescription(event.target.value)}
          rows={10}
          placeholder="Enter video description"
        />

      </Modal>

      {/* This Modal is for Upload a New Video */}
      <Modal
        title="Upload New Video"
        visible={uploadNewVideoModal}
        onCancel={() => setUploadNewVideoModal(false)}
        footer={[
          <Button key="back" className='create-new-project-btn' onClick={() => setUploadNewVideoModal(false)}>
            No
          </Button>,
          <Button key="submit" className='create-btn' onClick={handleUploadNewVideo}>
            Yes
          </Button>,
        ]}
      >
        <Divider className='new-Script-divider' />
        <p>Uploading a new video will delete all previously generated data. Are you sure you want to proceed?</p>
      </Modal>

    </div>
  )
}

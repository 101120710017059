import { Modal, Button, Typography } from 'antd';
import React, { useState } from 'react';
const { Title, Paragraph } = Typography;


export default function PolicyModal(props) {
    return (
        <>
            <Modal
                title="Refund & Cancellation Policy"
                visible={props.isModalVisible}
                onCancel={props.handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={props.handleCancel}>
                        OK
                    </Button>,
                ]}
            >
                <Typography>
                    <Title level={4}>Refund & Cancellation Policy</Title>
                    <Paragraph><strong>Effective Date:</strong> 27/02/2024</Paragraph>
                    <Title level={5}>1. Introduction</Title>
                    <Paragraph>
                        At <a href="https://writeonthis.com">https://writeonthis.com</a>, we strive to provide the best services to our customers. This Refund & Cancellation Policy outlines the conditions under which we offer refunds and cancellations for our credit-based services.
                    </Paragraph>
                    <Title level={5}>2. Refund Eligibility</Title>
                    <Paragraph>
                        <strong>Non-Refundable Credits:</strong> Once credits are used to generate AI audio, the transaction is final, and no refunds will be provided.
                    </Paragraph>
                    <Paragraph>
                        <strong>Unutilized Credits:</strong> Refunds are not available for unutilized credits. All purchases of credits are final and non-refundable.
                    </Paragraph>
                    <Title level={5}>3. Timeframes</Title>
                    <Paragraph>
                        <strong>Refund Request:</strong> As credits and services are non-refundable, there are no timeframes applicable for refund requests for used credits.
                    </Paragraph>
                    <Paragraph>
                        <strong>Processing Time:</strong> Any inquiries regarding transactions will be addressed within 5-7 business days.
                    </Paragraph>
                    <Title level={5}>4. Return/Replace Request Process</Title>
                    <Paragraph>
                        <strong>Inquiries:</strong> For any issues related to the services provided, users can contact us at rajveer@writeonthis.com. Our team will review the case and respond with appropriate solutions within 5-7 business days.
                    </Paragraph>
                    <Title level={5}>5. Cancellation Procedures</Title>
                    <Paragraph>
                        <strong>Service Cancellation:</strong> You may cancel your service at any time by contacting us at rajveer@writeonthis.com.
                    </Paragraph>
                    <Paragraph>
                        <strong>Effect of Cancellation:</strong> Cancelling your service does not entitle you to a refund for any used or unused credits. All credits purchased are non-refundable and non-transferable.
                    </Paragraph>
                    <Title level={5}>6. Fees and Requirements</Title>
                    <Paragraph>
                        <strong>Cancellation Fees:</strong> There are no fees associated with cancelling your account. However, please note that credits and services already provided cannot be refunded or retracted.
                    </Paragraph>
                    <Paragraph>
                        <strong>Requirements:</strong> To cancel your service, please provide your account details and the reason for cancellation when contacting us.
                    </Paragraph>
                    <Title level={5}>7. Contact Us</Title>
                    <Paragraph>
                        If you have any questions about our Refund & Cancellation Policy, please contact us at:
                    </Paragraph>
                    <Paragraph>
                        <strong>Email:</strong> rajveer@writeonthis.com
                    </Paragraph>
                    <Paragraph>
                        <strong>Phone:</strong> 7087743074
                    </Paragraph>
                    <Paragraph>
                        <strong>Operating Address:</strong> 681, 22nd A Main Road, HSR Layout, Bangalore, Karnataka, India - 560102
                    </Paragraph>
                    <Paragraph>
                        We aim to respond to all inquiries within 24 hours during our business hours.
                    </Paragraph>
                </Typography>
            </Modal>
        </>
    );
};


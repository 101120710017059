import { Modal, Button, Typography } from 'antd';
import React, { useState } from 'react';
const { Title, Paragraph } = Typography;

export default function TermsModal(props) {
    return (
        <>
            <Modal
                title="Refund & Cancellation Policy"
                visible={props.isModalVisible}
                onCancel={props.handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={props.handleCancel}>
                        OK
                    </Button>,
                ]}
            >
                <Typography>
                    <Title level={4}>Terms & Conditions</Title>
                    <Paragraph><strong>Effective Date:</strong> [Insert Date]</Paragraph>
                    <Title level={5}>1. Introduction</Title>
                    <Paragraph>
                        These Terms & Conditions ("Terms") govern your use of <a href="https://writeonthis.com">https://writeonthis.com</a> (the "Website") and the services provided by writeonthis ("we," "our," or "us"). By accessing or using our Website and services, you agree to comply with and be bound by these Terms.
                    </Paragraph>
                    <Title level={5}>2. Service/Product Details & User Responsibilities</Title>
                    <Paragraph>
                        <strong>Service Description:</strong> We provide AI audio generation from screen recordings. Users can upload screen recordings and generate AI audio by purchasing and using credits.
                    </Paragraph>
                    <Paragraph>
                        <strong>User Responsibilities:</strong> Users must provide accurate and complete information when using our services. Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
                    </Paragraph>
                    <Title level={5}>3. Payment Terms</Title>
                    <Paragraph>
                        <strong>Credits Purchase:</strong> Users must purchase credits to generate AI audio. Credits can be purchased through our Website using the available payment methods.
                    </Paragraph>
                    <Paragraph>
                        <strong>Non-Refundable Credits:</strong> All purchases of credits are final and non-refundable. Once credits are used to generate AI audio, the transaction is final, and no refunds will be provided.
                    </Paragraph>
                    <Title level={5}>4. Usage Restrictions</Title>
                    <Paragraph>
                        <strong>Prohibited Activities:</strong> Users may not use our services for any unlawful or prohibited activities, including but not limited to, creating offensive or harmful content, violating intellectual property rights, or engaging in fraudulent activities.
                    </Paragraph>
                    <Paragraph>
                        <strong>Account Termination:</strong> We reserve the right to terminate or suspend user accounts that violate these Terms or engage in prohibited activities.
                    </Paragraph>
                    <Title level={5}>5. Intellectual Property Ownership</Title>
                    <Paragraph>
                        <strong>Website Content:</strong> All content on our Website, including text, graphics, logos, and software, is the property of writeonthis and is protected by intellectual property laws.
                    </Paragraph>
                    <Paragraph>
                        <strong>User Content:</strong> Users retain ownership of the content they upload to our Website. By using our services, users grant us a non-exclusive, royalty-free license to use, reproduce, and distribute their content for the purpose of providing our services.
                    </Paragraph>
                    <Title level={5}>6. Limitation of Liability</Title>
                    <Paragraph>
                        To the fullest extent permitted by law, writeonthis shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of our Website or services. Our total liability to you for any claims arising from the use of our Website or services shall not exceed the amount you paid to us in the last 12 months.
                    </Paragraph>
                    <Title level={5}>7. Dispute Resolution Procedures</Title>
                    <Paragraph>
                        <strong>Governing Law:</strong> These Terms are governed by the laws of India, without regard to its conflict of law principles.
                    </Paragraph>
                    <Paragraph>
                        <strong>Dispute Resolution:</strong> Any disputes arising out of or in connection with these Terms shall be resolved through binding arbitration in accordance with the rules of [Arbitration Association]. The arbitration shall take place in India, and the arbitrator's decision shall be final and binding.
                    </Paragraph>
                    <Paragraph>
                        <strong>Waiver of Class Action:</strong> Users agree to waive any right to participate in class actions or class-wide arbitration.
                    </Paragraph>
                    <Title level={5}>8. Changes to These Terms</Title>
                    <Paragraph>
                        We may modify these Terms at any time. We will notify you of any changes by posting the new Terms on our Website. Your continued use of our Website and services after such changes constitutes your acceptance of the new Terms.
                    </Paragraph>
                    <Title level={5}>9. Contact Us</Title>
                    <Paragraph>
                        If you have any questions about these Terms, please contact us at rajveer@writeonthis.com or 7087743074.
                    </Paragraph>
                </Typography>
            </Modal>
        </>
    );
};


// App.js

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DemoWorkingScreen from './pages/demoAI/demoWorkingScreen'
import AiVideoGenerator from "./pages/aiVideo/aiVideo";
import Dashboard from './pages/dashboard/dashboard';
import Signup from './pages/userAuth/signup';
import Login from './pages/userAuth/login';
import AfterPaymentRedirect from "./pages/payments/afterPaymentRedirect";
import React from 'react';

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('userData');
    if (!isAuthenticated) {
        // If not authenticated, redirect to login
        return <Navigate to="/" />;
    }
    return children;
};

const App = () => {
    return (
        <Router>
            <Routes>
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute> } />
                {/* <Route path="demo/:id" element={<ProtectedRoute> <DemoWorkingScreen /> </ProtectedRoute> } /> */}
                <Route path="video/:id" element={<ProtectedRoute> <AiVideoGenerator /> </ProtectedRoute> } />
                {/* <Route path="pg/:orderId" element={<ProtectedRoute> <AfterPaymentRedirect /> </ProtectedRoute> } /> */}
            </Routes>
        </Router>
    );
};

export default App;

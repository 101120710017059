import { Input, Button, Alert, Divider, Layout, Row, Col } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import * as Constants from '../../common/constants';
import webLogo from '../../images/randomlogo.png';
import YouTube from 'react-youtube';
import PolicyModal from './policyModal'
import TermsModal from './termsModal'
import LoginImg from '../../images/login-page.gif'
import './css/login.css';

const { Content } = Layout;

const Login = () => {
  const [isPolicyModalDisplay, setIsPolicyModalDisplay] = useState(false);
  const [isTermsModalDisplay, setIsTermsModalDisplay] = useState(false);
  const [isLoginBtnLoading, setIsLoginBtnLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setIsPageLoading(true);
    const userData = localStorage.getItem('userData');
    if (userData) {
      window.location.href = '/dashboard';
    } else {
      localStorage.removeItem('userData');
    }
    setIsPageLoading(false);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleLoginClick = async () => {
    let errorMsg = "";
    if (!email) {
      errorMsg = "Please enter your email address.";
    } else if (!password) {
      errorMsg = "Please enter your password.";
    } 

    if (errorMsg) {
      setError(errorMsg);
    } else {
      setIsLoginBtnLoading(true);
      fetch(Constants.APIS.USER_LOGIN, {
        method: "POST",
        mode: 'cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          email: email,
          password: password
        })
      }).then(response => response.json())
        .then(data => {
          setIsLoginBtnLoading(false);
          if (data.responseCode === Constants.CODE_SUCCESS) {
            localStorage.setItem('userData', JSON.stringify(data.data));
            window.location.href = '/dashboard';
          } else {
            setError(data.responseMessage);
          }
        });
    }
  };

  return (!isPageLoading &&
    <Layout className='login-layout'>
      <Content>
        <Row className='login-main-content'>
          <Col className='login-container'>
            <Row>
              <Col span={2} className='login-logo-container'>
                <img src={webLogo} className='login-weblogo' alt='Web Logo' />
              </Col>
              <Col span={10}>
                <h1 className='login-name-heading'>Write On This</h1>
                <p className='login-subtitles'>Create Professional Demo Videos, Hassle-Free.</p>
                {/* <h5 className='login-platform-Info'>Platform Information</h5> */}
                <img src={LoginImg} height='500px' width='400px'/>
                {/* <div>
                  <YouTube
                    videoId={'ZK-rNEhJIDs'}
                    className='login-yt-video'
                    opts={{
                      height: '300',
                      width: '400',
                      playerVars: {
                        autoplay: 1,
                        controls: 1,
                        rel: 0,
                        modestbranding: 1,
                        fs: 1,
                      },
                    }}
                  />
                </div> */}
              </Col>
              <Col span={1}>
                <Divider type="vertical" className='login-divider' />
              </Col>
              <Col span={7} className='login-main-right-section'>
                <div className="login-rightSection">
                  {error && (
                    <Row justify='center' style={{ marginBottom: '10px' }}>
                      <Alert message={error} type="error" showIcon />
                    </Row>
                  )}
                  <p className="login-welcome-txt"> 𝓦𝓮𝓵𝓬𝓸𝓶𝓮 </p>
                  <p className="login-sub-text">Please login to your account</p>
                  <Input
                    onChange={handleEmailChange}
                    type='email'
                    className="login-email"
                    size="large"
                    placeholder="Email" />
                  <Input.Password
                    onChange={handlePasswordChange}
                    className="login-password"
                    size="large"
                    placeholder="Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <Button className='loginBtn' loading={isLoginBtnLoading}
                    onClick={handleLoginClick}>
                    Login
                  </Button>
                  <div className="login-signup">
                    Don’t have an account?<a href="/signup"> Signup</a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify='center' className='login-policy'>
              <Col
                className='login-privacy-text'
                onClick={() => setIsTermsModalDisplay(true)}
              >
                Terms
              </Col>
              <Col
                onClick={() => setIsPolicyModalDisplay(true)}
                className='login-privacy-text'>
                Policy
              </Col>
              {/* <Col className='login-privacy-text'>Contact Us</Col> */}
            </Row>
          </Col>
        </Row>
      </Content>
      <PolicyModal
        isModalVisible={isPolicyModalDisplay}
        handleCancel={() => setIsPolicyModalDisplay(false)}
      />
      <TermsModal
        isModalVisible={isTermsModalDisplay}
        handleCancel={() => setIsTermsModalDisplay(false)}
      />
    </Layout>
  )
}

export default Login;
